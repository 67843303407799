<template>
  <span>
    <v-btn small class="ml-2 mt-4" color="warning" @click="dialog = true">
      <v-icon class="mr-2">mdi-note-outline</v-icon>Job Order</v-btn
    >
    <v-dialog v-model="dialog" width="500" persistent>
      <v-card>
        <v-card-text class="pa-7">
          <h1>Job Order Modal</h1>
          <div class="d-flex">
            <v-select
              :items="item.problems"
              item-text="problem_description"
              v-model="selected_problem"
            ></v-select>
            <v-btn small color="primary mt-2" @click="add_to_list"
              >Add to list</v-btn
            >
          </div>

          <p class="green--text font-weight-bold">Problems List:</p>
          <ol>
            <li v-for="problem in problems_list" :key="problem">
              {{ problem }}
            </li>
          </ol>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="print_job_order(1)" color="primary"
            >Print JO Form</v-btn
          >

          <v-btn @click="dialog = false">Close</v-btn>
          <v-spacer></v-spacer>
          <p class="caption grey--text">jobOrderModal.vue</p>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["item"],
  data() {
    return {
      dialog: false,
      problems_list: [],
      selected_problem: null,
    };
  },
  computed: {
    ...mapGetters({
      problems: "problem/problems",
      default_server_url: "auth/default_server_url",
    }),
  },
  methods: {
    add_to_list() {
      this.problems_list.push(this.selected_problem);
    },
    print_job_order(number) {
      var url;
      if (number == 1) {
        url =
          this.default_server_url +
          "/print_job_order_slip/" +
          JSON.stringify(this.problems_list) +
          "/" +
          this.item.id;
        window.open(url);
      } else {
        url =
          this.default_server_url +
          "/print_work_history_slip/" +
          JSON.stringify(this.problems_list) +
          "/" +
          this.item.id;
        window.open(url);
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
